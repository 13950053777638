<template>
  <div class="">
    <h3>Edit Runner Request</h3>
    <form>
      <div class="form-group">
        <label for="catDescription">ID</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.runner_category_id"
          id="request_id"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Category</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.category"
          id="request_category"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Title</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.title"
          id="request_title"
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Description</label>
        <textarea
          class="form-control"
          id="request_description"
          rows="3"
          v-model="runnerRequest.description"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="catDescription">Status</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.status"
          id="request_status"
          disabled
        />
      </div>

      <button
        type="submit"
        class="btn btn-primary ls-submit-button"
        @click.prevent="onCreateRunnerRequest()"
      >
        Update
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "EditRunnerRequest",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.request_id = this.$route.params.id;
    this.getRunnerDetails();
  },
  data() {
    return {
      request_id: "",
      runnerCategories: [],
      myRequest: {},
      runnerRequest: {
        description: "",
        runner_category_id: "",
        category: "",
        status: "",
        date: "",
        title: "",
      },
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunnerDetails() {
      axios
        .get("v1/getrunner/get-runners/" + this.request_id)
        .then((response) => {
          //console.log("Get Runner Request : " + JSON.stringify(response.data));
          this.myRequest = response.data;
          this.runnerRequest.runner_category_id = this.request_id;
          this.runnerRequest.description = response.data.body;
          this.runnerRequest.status = response.data.status;
        });
    },

    onCreateRunnerRequest() {
      //the request method to save the request
      let data = {
        body: this.runnerRequest.description,
      };

      axios
        .patch("v1/getrunner/get-runners/" + this.request_id, data)
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.$toasted.show("Request successfully updated. ");
          }
        })
        .catch((error) => {
          this.$toasted.error("Saving failed, please try again");
        });
    },
  },
};
</script>
